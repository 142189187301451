export const getLocalizedOrValue = (i18n, code, value) => {
  return i18n.te(code) ? i18n.t(code) : value
}

export const getLocalizedServerError = (i18n, codeWithoutErrorNumber, response) => {
  const errorCode = response ? response?.data?.code : null
  if (errorCode) {
    if (i18n.te(codeWithoutErrorNumber + errorCode)) {
      return i18n.t(codeWithoutErrorNumber + errorCode)
    }

    if (i18n.te('errors.general.' + errorCode)) {
      return i18n.t('errors.general.' + errorCode)
    }
  }
  return i18n.t('errors.serverError', { server_code: response.status })
}
