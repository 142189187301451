import { defineAsyncComponent } from 'vue'
import { $vfm } from 'vue-final-modal'

export function useModal() {
  const openModal = (title, content, callback) => {
    $vfm.show({
      component: defineAsyncComponent(() => import('@/application/components/modals/InfoModal.vue')),
      bind: {
        title,
        content,
        hide: () => {
          if (callback) {
            callback()
          }
        },
      },
      on: {
        close: () => {
          if (callback) {
            callback()
          }
          $vfm.hideAll()
        },
      },
    })
  }
  return {
    openModal,
  }
}
