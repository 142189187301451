<template>
  <div
    class="task-detail-right"
  >
    {{ rerender }}
    <div class="card">
      <div class="card-header">
        <h5>{{ $t('lookupBulkForm') }}</h5>
      </div>
      <div class="card-block task-details">
        <lookup-form :license="license"></lookup-form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { useLookupCreate } from '@/application/composables/lookupCreate.js'
import { store } from '@/application/store'
import { lookupCountLimit } from '@/application/utils/limits.js'

export default {
  name: 'LookupFormWrapper',
  components: {
    'lookup-form': defineAsyncComponent(() => import('@/application/components/forms/LookupForm.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
    rerender: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const resetDisabled = computed(() => {
      const data = store.getters.getSingleFormCache(props.license)
      return !data || Object.values(data).filter(item => !!item).length === 0
    })
    const {
      formSchema: singleFormSchema,
      submitButton: singleSubmitButton,
      resetButton,
      submit: singleSubmit,
      initialFieldValue: singleInitialValue,
      cleanFieldValue,
      bulkStatuses,
      onChangeHandler,
      needPrevalidation,
    } = useLookupCreate(props.license, lookupCountLimit)

    return {
      resetDisabled,
      singleFormSchema,
      singleSubmitButton,
      singleSubmit,
      resetButton,
      singleInitialValue,
      bulkStatuses,
      onChangeHandler,
      cleanFieldValue,
      needPrevalidation,
    }
  },
}
</script>
