import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { sendLookupRequest } from '@/application/services/message.js'
import { useSubmitLookup } from '@/application/composables/submitLookup.js'
import { store } from '@/application/store'

export function useLookupCreate(license, limit) {
  const i18n = useI18n()
  const successLangPhrase = 'bulkMessagesSuccessResponse'
  const senderNames = license.sender_names
  const rememberedData = store.getters.getBulkFormCache(license)

  const cleanFieldValue = {
    lookup_agreement: false,
    lookup_contacts: '',
  }
  // initial values of form
  const formdata = {
    ...cleanFieldValue,
    ...rememberedData,
  }
  const { submit, isDisabled } = useSubmitLookup(license, sendLookupRequest, successLangPhrase, { ...cleanFieldValue }, limit)
  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('start'),
  }))

  const resetButton = computed(() => ({
    wrapper: 'd-flex flex-row justify-content-end',
    class: 'btn btn-outline-danger shadow-2 mt-4',
    text: i18n.t('reset'),
    clearStorage: () => {
      store.dispatch('clearFormData', { license, type: 'LOOKUP' })
    },
  }))

  return {
    license,
    submitButton,
    resetButton,
    submit,
    formdata,
    cleanFieldValue,
    senderNames,
    isDisabled: isDisabled,
    needPrevalidation: Object.values(formdata).join('') !== Object.values(cleanFieldValue).join(''),
    onChangeHandler: (data) => {
      store.dispatch('saveLookupFormData', { license, data })
    },
    onChangeDateHandler: (date) => {
      const currentData = store.getters.getBulkFormCache(license)
      store.dispatch('saveLookupFormData', { license, data: { ...currentData, bulk_from_date: date } })
    },
  }
}
