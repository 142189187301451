import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal.js'
import { useExtractRecipients } from '@/application/composables/extractRecipients.js'
import { store } from '@/application/store'
import { getLocalizedServerError } from '@/application/utils/localization'

export function useSubmitLookup(license, request, successMessage, initialFields, bulkLimit) {
  const { extractRecipients } = useExtractRecipients()
  const isDisabled = ref(false)
  const i18n = useI18n()
  const { openModal } = useModal()
  const formatFormData = (data) => {
    try {
      data.lookup_contacts = extractRecipients(data.lookup_contacts)
    } catch {
      data.lookup_contacts = Array.from(data.lookup_contacts)
    }

    if (bulkLimit !== undefined) {
      data.lookup_contacts = data.lookup_contacts.slice(0, bulkLimit)
    }

    const obj = {}
    Object.keys(data).forEach(key => {
      obj[key.replace('lookup_', '')] = data[key]
    })

    if (obj.contacts.length === 1) {
      obj.contact = obj.contacts[0]
      delete obj.contacts
    }
    if (obj.agreement) delete obj.agreement
    return obj
  }
  const submit = (data, { resetForm, setValues }) => {
    const dataFromStore = store.getters.getLookupFormCache(license)
    const formData = JSON.parse(JSON.stringify(dataFromStore))

    isDisabled.value = true
    request(formatFormData(formData), {
      Authorization: license.license_key,
      'Loop-Secret-Key': license.secret_api_key,
    })
      .then(res => {
        isDisabled.value = false
        openModal(i18n.t('success'), i18n.t(successMessage, { count: res.data.count }))
        store.dispatch('clearFormData', { license, type: 'LOOKUP' })
        resetForm()
        setValues(initialFields)
      })
      .catch(err => {
        isDisabled.value = false
        openModal(i18n.t('error'), err.response ? getLocalizedServerError(i18n, 'forms.submitMessage.errors.', err.response) : i18n.t('error'))
      })
  }
  return {
    submit,
    isDisabled,
  }
}
