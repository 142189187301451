import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'

const domain = process.env.VUE_APP_API_PATH
const lookup_domain = process.env.VUE_APP_LOOKUP_API_PATH
const singleMessageAPI = `${domain}api/v1/message/send/`
const bulkMessageAPI = `${domain}api/v1/message/send-bulk/`
const durationApi = `${domain}api/v1/dashboard/send-duration/`
const lookupApi = `${lookup_domain}api/v1/contact/lookup/`

export const sendSingleMessage = (data, headers) => interceptor(axios).post(singleMessageAPI, data, { headers })
export const sendBulkMessage = (data, headers) => interceptor(axios).post(bulkMessageAPI, data, { headers })
export const getDuration = (count) => interceptor(axios).get(durationApi, { params: { count: count } })
export const sendLookupRequest = (data, headers) => interceptor(axios).post(lookupApi, data, { headers })
